'use client';

import { getSiteMode } from '@wt/utilities/siteMode';
import { useEffect } from 'react';

export default function Favicons() {
  const icons = [
    {
      rel: 'apple-touch-icon',
      type: 'image/png',
      sizes: '180x180',
      name: 'apple-touch-icon',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      name: 'favicon-32x32',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      name: 'favicon-16x16',
    },
    {
      rel: 'icon',
      type: 'image/x-icon',
      sizes: undefined,
      name: 'favicon',
    },
  ];

  useEffect(() => {
    const iconSuffix = {
      wheretaken: '-wheretaken',
      whentaken: '-whentaken',
      'whentaken-movies': '-whentaken-movies',
    }[getSiteMode()];
    for (const i of icons) {
      const extension = i.type == 'image/x-icon' ? 'ico' : 'png';
      const link = document.createElement('link');
      link.rel = i.rel;
      link.type = i.type;
      if (i.sizes) link.setAttribute('sizes', i.sizes);
      link.href = `/${i.name}${iconSuffix}.${extension}`;
      document.head.appendChild(link);
    }
  }, []);
  return <></>;
}
