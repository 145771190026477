'use client'
import "react-toastify/dist/ReactToastify.css";

import React from "react";
import {Flip, ToastContainer} from "react-toastify";

export const ToastifyProvider = () => {
    return (
      <ToastContainer
        hideProgressBar
        position="top-center"
        transition={Flip}
        autoClose={2000}
        bodyClassName="font-bold text-center justify-center items-center"
        toastClassName="flex justify-center my-2 max-w-full overflow-y-auto max-h-[calc(100vh-100px)"
      />
    );
}

export default ToastifyProvider;